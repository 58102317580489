import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import { iRestrictionsKeys } from '@/types/common/restrictions'
import { can } from '@/helpers/utils'
import ForbiddenPage from '@/pages/ForbiddenPage.vue'
import store from '@/store'
import { watch } from 'vue'

const routes: Array<RouteRecordRaw & { meta?: { restrictions?: iRestrictionsKeys[] }}> = [
  {
    path: '/',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/ProfilePage.vue')
  },
  {
    path: '/disposer',
    name: 'disposer',
    component: () => import(/* webpackChunkName: "disposer" */ '../pages/DisposerPage.vue'),
    meta: {
      restrictions: ['DISPOSER_ONLY']
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../pages/UserAccountPage.vue'),
    meta: {
      restrictions: ['VIEW_ACCOUNT_LIST']
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../pages/UsersPage.vue'),
    meta: {
      restrictions: ['VIEW_USER_LIST']
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../pages/SettingsPage.vue'),
    meta: {
      restrictions: ['VIEW_APPLICATION_SETTINGS']
    }
  },
  {
    path: '/api',
    name: 'api',
    component: () => import(/* webpackChunkName: "api" */ '../pages/ApiPage.vue'),
    meta: {
      restrictions: ['VIEW_API_TOKEN', 'UPDATE_API_TOKEN']
    }
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "customer" */ '../pages/CustomerPage.vue'),
    meta: {
      restrictions: ['VIEW_OFFER_LIST']
    }
  },
  {
    path: '/trader',
    name: 'trader',
    component: () => import(/* webpackChunkName: "trader" */ '../pages/TraderPage.vue'),
    meta: {
      restrictions: ['VIEW_OFFER_LIST']
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "support" */ '../pages/SupportPage.vue'),
    meta: {
      restrictions: ['VIEW_CHAT']
    }
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import(/* webpackChunkName: "tickets" */ '../pages/TicketsPage.vue'),
    meta: {
      restrictions: ['VIEW_TICKETS']
    }
  },
  {
    path: '/tickets/:chatId',
    name: 'tickets-chat',
    component: () => import(/* webpackChunkName: "tickets" */ '../pages/TicketsChatPage.vue'),
    props: route => ({
      chatId: route.params.chatId
    }),
    meta: {
      restrictions: ['VIEW_TICKETS']
    }
  },
  {
    path: '/register-confirm/:email/:code',
    name: 'register-confirm',
    component: () => import(/* webpackChunkName: "registration-confirm" */ '../pages/RegistrationConfirmPage.vue'),
    props: route => ({
      email: route.params.email,
      code: route.params.code
    })
  },
  {
    path: '/api-documentation',
    name: 'api-documentation',
    component: () => import(/* webpackChunkName: "api-documentation" */ '../pages/ApiDocumentationPage.vue')
  },
  {
    path: '/statistic',
    name: 'statistic',
    component: () => import(/* webpackChunkName: "statistic" */ '../pages/StatisticPage.vue')
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFoundPage },
  { path: '/forbidden', name: 'forbidden', component: ForbiddenPage }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to: RouteLocationNormalized & { meta?: { restrictions?: iRestrictionsKeys[] }}, from, next) => {
  if (!to.name) { return next() }

  const restrictions: iRestrictionsKeys[]|undefined = to.meta?.restrictions
  if (restrictions && restrictions.length > 0) {
    // Ждем когда пользователь загрузится, чтобы проверить доступ к странице
    await new Promise(resolve => {
      if (store.state.userLoaded) {
        resolve(null)
        return
      }

      const stopWatcher = watch(() => store.state.userLoaded, () => {
        resolve(null)
        stopWatcher()
      })
    })

    const canAccess = restrictions
      .map(restriction => can(restriction))
      .reduce((a, b) => a || b)

    if (!canAccess) {
      return next({ name: 'forbidden' })
    }
  }

  return next()
})

export default router
