import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import ruRU from 'element-plus/es/locale/lang/ru'

createApp(App)
  .use(router)
  .use(ElementPlus, { locale: ruRU })
  .mount('#app')
