<template>
  <div class="user-info">
    <div class="user-info-name">{{ name }}</div>
    <div>
      <div v-if="can('USDT_ACCOUNT')" class="balance">{{ balanceFormatted }}</div>

      <el-tooltip v-if="can('USDT_ACCOUNT') && rates.UsdtToRub" placement="bottom">
        <template #default>
          <div class="balance">
            {{ formatRub(store.state.balance / 1000000 * 1.03 * parseFloat(rates.UsdtToRub)) }}
          </div>
        </template>

        <template #content>
          {{ formatRub(store.state.balance / 1000000 * parseFloat(rates.UsdtToRub)) }} + 3%
        </template>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">

import store from '@/store'
import { computed } from 'vue'
import { can } from '@/helpers/utils'
import { formatUsdt, formatRub } from '@/helpers/format'

const balanceFormatted = computed(() => formatUsdt(store.state.balance))
const name = computed(() => store.state.user?.name)
const rates = computed(() => store.state.rates)
</script>

<style lang="scss">
.user-info {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .balance {
    font-size: .7em;
    font-weight: bolder;
    text-align: right;
  }
}
</style>
