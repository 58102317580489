<script setup lang="ts">
import { reactive, ref } from 'vue'
import store from '@/store'
import { ElNotification, FormInstance, FormRules } from 'element-plus'
import useFormRequest from '@/composables/useFormRequest'
import { iLoginForm } from '@/types/common/requests'
import { login } from '@/store/actions/auth'
import VueTurnstile from 'vue-turnstile'

const formRef = ref<FormInstance>()

const formRequest = useFormRequest<iLoginForm>()

const turnstileSiteKey = process.env.VUE_APP_CLOUDFLARE_SITE_KEY

const form = reactive<iLoginForm>({
  email: '',
  password: '',
  captcha: ''
})

const rules = reactive<FormRules<iLoginForm>>({
  email: [
    { required: true, message: 'Введите email', trigger: 'blur' }
  ],
  password: [
    { required: true, message: 'Введите пароль', trigger: 'blur' }
  ]
})

const handleSubmit = async () => {
  await formRef.value?.validate((valid: boolean) => {
    if (valid) {
      formRequest.tryRequest(async () => {
        store.login(await login(form))
        ElNotification({ message: 'Вы успешно авторизовались', type: 'success' })
      })
    }
  })
}

</script>

<template>
  <el-container>
    <el-main>
      <div class="logo-wrapper">
        <img src="@/assets/logo-2.jpg" alt="logo" />
      </div>
      <el-card class="login-card">
        <template #header>Авторизация</template>

        <el-form
          :model="form"
          :rules="rules"
          ref="formRef"
          status-icon
          @submit.prevent="handleSubmit"
          v-loading="formRequest.state.pending"
        >

          <el-form-item prop="email" :error="formRequest.state.errors.email">
            <el-input v-model="form.email" placeholder="E-mail" type="text" autocomplete="email" />
          </el-form-item>

          <el-form-item prop="password" :error="formRequest.state.errors.password">
            <el-input v-model="form.password" placeholder="Пароль" type="password" autocomplete="password" />
          </el-form-item>

          <el-form-item prop="captcha" :error="formRequest.state.errors.captcha" class="captcha-wrapper">
            <vue-turnstile v-model="form.captcha" :site-key="turnstileSiteKey" theme="light" size="flexible" />
          </el-form-item>

          <el-form-item>
            <div class="text-center">
              <el-button type="primary" native-type="submit">Войти</el-button>
            </div>
          </el-form-item>
        </el-form>

      </el-card>
    </el-main>
  </el-container>
</template>

<style lang="scss" scoped>

.login-card {
  max-width: 400px;
  margin: auto;
}

.captcha-wrapper .el-form-item__content > div {
  width: 100%;
}

.logo-wrapper {
  text-align: center;

  img {
    max-width: 200px;
  }
}

</style>
