import { iUserRole } from './models'

type iRestrictionConfig = {
  name: string
  allowedRoles: iUserRole[]
}
export type iRestrictionsKeys =
  | 'VIEW_PROFILE'
  | 'VIEW_ACCOUNT_LIST'
  | 'CREATE_ACCOUNT'
  | 'UPDATE_ACCOUNT'
  | 'SET_CURRENT_ACCOUNT'
  | 'DELETE_ACCOUNT'
  | 'BANK_ACCOUNT'
  | 'USDT_ACCOUNT'
  | 'VIEW_USER_LIST'
  | 'VIEW_APPLICATION_SETTINGS'
  | 'UPDATE_APPLICATION_SETTINGS'
  | 'VIEW_API_TOKEN'
  | 'UPDATE_API_TOKEN'
  | 'USE_API'
  | 'VIEW_CHAT'
  | 'CREATE_USER'
  | 'DELETE_USER'
  | 'SET_USER_RATING'
  | 'VIEW_OFFER_LIST'
  | 'CREATE_OFFER'
  | 'COMPLETE_EXCHANGE'
  | 'VIEW_TICKETS'
  | 'ACCEPT_TICKET'
  | 'CLOSE_TICKET'
  | 'DISPOSER_ONLY'
  | 'VIEW_ALL_STATISTIC'

export type iRestrictions = Record<iRestrictionsKeys, iRestrictionConfig>

export const restrictions: iRestrictions = {
  VIEW_ALL_STATISTIC: {
    name: 'Просмотр статистики',
    allowedRoles: ['admin', 'manager']
  },
  VIEW_PROFILE: {
    name: 'Просмотр профиля',
    allowedRoles: ['admin', 'manager', 'partner', 'client']
  },
  VIEW_ACCOUNT_LIST: {
    name: 'Просмотр счётов пользователя',
    allowedRoles: ['partner', 'client', 'disposer']
  },
  CREATE_ACCOUNT: {
    name: 'Создание счёта пользователя',
    allowedRoles: ['partner', 'client', 'disposer']
  },
  UPDATE_ACCOUNT: {
    name: 'Изменение счёта пользователя',
    allowedRoles: ['partner', 'client', 'disposer']
  },
  SET_CURRENT_ACCOUNT: {
    name: 'Изменение текущего счёта пользователя',
    allowedRoles: ['partner', 'client', 'disposer']
  },
  DELETE_ACCOUNT: {
    name: 'Удаление счёта пользователя',
    allowedRoles: ['partner', 'client', 'disposer']
  },
  BANK_ACCOUNT: {
    name: 'Банковский счёт',
    allowedRoles: ['partner', 'client', 'disposer']
  },
  USDT_ACCOUNT: {
    name: 'USDT счёт',
    allowedRoles: ['partner', 'client', 'disposer']
  },
  VIEW_USER_LIST: {
    name: 'Просмотр списка пользователей',
    allowedRoles: ['admin', 'manager']
  },
  VIEW_APPLICATION_SETTINGS: {
    name: 'Просмотр настроек приложения',
    allowedRoles: ['admin']
  },
  UPDATE_APPLICATION_SETTINGS: {
    name: 'Изменение настроек приложения',
    allowedRoles: ['admin']
  },
  VIEW_API_TOKEN: {
    name: 'Работа с API',
    allowedRoles: ['disposer']
  },
  UPDATE_API_TOKEN: {
    name: 'Изменение API токена',
    allowedRoles: ['disposer']
  },
  USE_API: {
    name: 'Использование API',
    allowedRoles: ['disposer']
  },
  VIEW_TICKETS: {
    name: 'Доступ к тикетам',
    allowedRoles: ['manager']
  },
  ACCEPT_TICKET: {
    name: 'Принятие тикета',
    allowedRoles: ['manager']
  },
  CLOSE_TICKET: {
    name: 'Закрытие тикета',
    allowedRoles: ['manager']
  },
  VIEW_CHAT: {
    name: 'Доступ чату с поддержкой',
    allowedRoles: ['partner', 'client', 'disposer']
  },
  CREATE_USER: {
    name: 'Создание пользователя',
    allowedRoles: ['admin']
  },
  DELETE_USER: {
    name: 'Удаление пользователя',
    allowedRoles: ['admin']
  },
  SET_USER_RATING: {
    name: 'Изменение рейтинга пользователя',
    allowedRoles: ['admin', 'manager']
  },
  VIEW_OFFER_LIST: {
    name: 'Просмотр предложений',
    allowedRoles: ['partner', 'client']
  },
  CREATE_OFFER: {
    name: 'Создание оффера',
    allowedRoles: ['partner', 'client']
  },
  COMPLETE_EXCHANGE: {
    name: 'Завершение обмена',
    allowedRoles: ['partner', 'client']
  },
  DISPOSER_ONLY: {
    name: 'Только для управляющего',
    allowedRoles: ['disposer']
  }
}
