import store from '@/store'
import { iRestrictionsKeys, restrictions } from '@/types/common/restrictions'

export const Nothing = () => {
  // Nothing to do
}

export const can = (restrictionName: iRestrictionsKeys): boolean => {
  const user = store.state.user
  if (!user) return false

  const restriction = restrictions[restrictionName]
  if (!restriction) return false

  return restriction.allowedRoles.includes(user.role)
}

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
